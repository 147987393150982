export default {
    data() {
        return {
            activeAvatar: "https://adhera-users.s3.eu-west-1.amazonaws.com/active-avatar.svg",
            inActiveAvatar: "https://adhera-users.s3.eu-west-1.amazonaws.com/avatar.svg",
        };
    },
    methods: {
        chatIsActive({ selectedChat, chat }) {
            return selectedChat.id == chat.id;
        },
        getDefaultAvatar(data) {
            if (!data) {
                return "";
            }

            return `${
                data.selectedChat && this.chatIsActive(data)
                    ? this.activeAvatar
                    : this.inActiveAvatar
            }`;
        },
    },
};
