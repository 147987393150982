<template>
    <div class="messages">
        <v-container>
            <v-row>
                <v-col cols="12" class="main-title pb-0 pt-0">
                    <v-tabs align-with-title>
                        <v-tabs-slider
                            :color="
                                $store.getters['auth/user']
                                    ? $store.getters['auth/user'].theme_config.primary_color
                                    : ''
                            "
                        ></v-tabs-slider>

                        <v-tab> {{ $t("your_messages") }} </v-tab>
                    </v-tabs>
                </v-col>
            </v-row>

            <v-row class="mt-0">
                <div class="col-5 col-lg-4">
                    <ChatsNavigator
                        :chats="chats"
                        :selectedChat="selectedChat"
                        @chatSelected="selecteChat($event)"
                    />
                </div>
                <div class=" col-6 col-lg-8 pt-2 pl-lg-15">
                    <MessagesContainer
                        :chat="selectedChat"
                        :messageAdded="messageAdded"
                        :userHasNoChats="userHasNoChats"
                        @chatFetched="messageAdded = false"
                        @readChatMessages="readChatMessages($event)"
                    />
                </div>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import ChatsNavigator from "@/components/messages/ChatsNavigator.vue";
import MessagesContainer from "@/components/messages/MessagesContainer.vue";

export default {
    name: "messages",

    components: {
        ChatsNavigator,
        MessagesContainer,
    },

    data() {
        return {
            chats: null,
            selectedChat: null,
            messageAdded: false,
        };
    },

    computed: {
        userHasNoChats() {
            return Array.isArray(this.chats) && this.chats.length == 0;
        },
    },

    async mounted() {
        this.chats = await this.getChats();

        if (this.chats && this.chats.length > 0) {
            this.selectedChat = this.chats[0];
            this.readChatMessages(this.chats[0].id);
        }

        this.listenToUnreadMessages();
        this.listenToChatCreated();
    },

    methods: {
        ...mapActions({
            getChats: "messages/getChats",
        }),
        selecteChat(chatId) {
            if (chatId == this.selectedChat.id) {
                return;
            }

            this.selectedChat = chatId ? this.chats.find((chat) => chat.id == chatId) : this.chats;

            this.$store.commit("messages/APPEND_TO_CHAT_WITH_MESSAGES", []);

            this.readChatMessages(this.selectedChat.id);
        },
        readChatMessages(chatToReadId) {
            const chatToRead = this.chats.find((chat) => chat.id == chatToReadId);

            this.$axios
                .post("messenger/chats/last-seen", {
                    patient_id: chatToRead.patient_id,
                })
                .then((res) =>
                    this.chats.map((chat) => 
                        chat.id == chatToRead.id ? (chat.unread_messages_count = 0) : ''
                    )
                )
                .catch((err) => console.log(err));
        },
        listenToUnreadMessages() {
            const AuthUserId = this.$store.getters["auth/user"].id;

            this.$echo.private("chats").listen(".chat.message.added", async (message) => {
                this.messageAdded = true;

                const chatIndex = this.chats.findIndex(
                    (chat) => chat.id == message.messenger_chat_id
                );

                if (chatIndex == -1) {
                    return (this.chats = await this.getChats());
                }

                this.chats[chatIndex].unread_messages_count = message.user_id !== AuthUserId ? 1 : 0;
                this.chats[chatIndex].last_message = message;
                
                // move chat to top
                let newestChat = this.chats[chatIndex];
                this.chats.splice(chatIndex, 1);
                this.chats.unshift(newestChat);
            });
        },
        listenToChatCreated() {
            this.$echo.private("chats").listen(".chat.created", async (chat) => {
                this.chats.push(chat);
            });
        },
    },
};
</script>

<style lang="scss" scoped src="@/assets/sass/views/dashboard/messages/index.scss"></style>
